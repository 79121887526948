.profile {
    ul {
        margin: 0;
        padding: 0;
        li {
            
            a {
                display: flex;
                align-items: center;
                &.disable {
                    pointer-events: none;
                    opacity: .5;
                    color: $color-text;
                }
                .material-icons {
                    margin-right: .5rem;
                }
            }
            margin: 0;
            padding: 0;
            padding-bottom: .5rem;
            list-style: none;
        }
    }
    h2 {
        margin-bottom: 2rem;
    }
    .grid-container {
        
        .grid {
            
            .item {
                
                .caption {
                    padding-bottom: 10px;
                    p {
                        min-height: 1.2em * 4;
                        margin-bottom: 0;
                        font-size: 1em;
                    }
    
                }
            }
            
        }
    }
    &.manage-classes {
        .col-md-3 {
            h3 {
                min-height: 142px;
            }
            min-height: 300px;
        }
    }
}

.profile-container {
    display: flex;
    align-items: center;
    justify-content: end;

    .logout-btn {
        margin-right: 1rem;
        margin-bottom: .5rem;
    }
    .text-link {
        display: inline-block;
        margin-bottom: .5rem;
        margin-right: .3rem;
    }
    .profile-btn {
        color: $brand;
        
        
        display: inline-block;
        
        .icon {
            font-size: 3rem;
            &:before {
                color: $brand;
            }
        }
    }
}