.modules-list {
    .grid {
        &.classic {
            .item { 
                .caption {
                    .tag {
                        min-height: 36px;
                    }
                }
            }
        }
    }
}