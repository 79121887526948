body {
    
    &.dark-mode {
        color: #eee;    
        background: #202c33;
        .hero h1 {
            color: #fff;
        }
        .profile .grid-container .grid .item .caption p, p,.grid.classic .item .caption h3, .grid.classic .item .caption h4 {
            color: $color-text-dark-mode;
        }
        footer.bottom {
            background-color: #111b21;
            color: $color-text-dark-mode;
            a {
                color: $brand;
            }
        }
        .form .tag {
            color: #8796a0;
        }
        .brand img {
            filter: brightness(1) invert(1);
        }
        .grid.classic .item {
            .thumb, img {
                transition: $main-transition;
                opacity: .6;
            }
            &:hover {
                .thumb, img {
                    opacity: 1;
                }
            }
        }
        
    }
}
a {
    text-decoration: none;
    position: relative;
    transition: color .2s ease-out;
    color: $brand;
    // &[data-label] {
    //     display: inline-block;
    //     &:after {
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         content: attr(data-label);
    //         text-decoration: underline;
    //         text-decoration-color: $brand;
    //         color: rgba(255, 255, 255, 0);

    //         opacity: 0;
    //         transform: translateY(5px);

    //         transition-property: opacity, transform;
    //         transition-duration: 0.15s;
    //         transition-timing-function: ease-out;
    //     }

    //     &:hover {
    //         text-decoration: none;
    //         color: $color-text;
    //         &:after {
    //             top: 0;
    //             opacity: 1;
    //             transform: translateY(0px);
    //         }
    //     }
    // }
    &:hover {
        text-decoration: none;
        color: $color-secundary;
    }
}

.brand {
    img {
        max-width: 60px;
    }
}

ul.navigation li a:hover, ul.navigation li a.active, h4, h6, .grid.classic .item:hover h3, .grid.classic .item:hover h4, .filter-container ul.filters li a.filter:hover, .filter-container ul.filters li a.filter.active, .social-links li a:hover, .label:hover, ul.item-list li a:hover, ul.item-list li span:hover,.toggleContainer.active .option:hover, .toggleContainer.active .option.active {
    color: $brand;
    outline: none;
}

html.dark .btn-default:hover, html.dark .btn-default.active, html.dark .btn-default:focus, .grid.masonry .item .thumb, .grid.classic .item .thumb, .grid.classic .item .thumb .btn, a.item:hover .btn-secondary, .btn.btn-default:hover, .btn.btn-default:focus, .btn.btn-default:active, .btn.btn-color:hover, .btn.btn-color:focus, .btn.btn-color:active, .btn.btn-color, .btn.active, .pagination .previous:hover, .pagination .next:hover {
    background-color: $brand;
    outline: none;
}

.label:hover {
    color: $brand !important;
    border-color: $brand;
}
ul.item-list li a:hover:before, ul.item-list li span:hover:before {
    border-color: $brand;
}

.grid.classic .item .thumb {
    background-color: transparent;
}

.grid.classic .item h4 {
    min-height: 46px;
}

.video_container {
    position: relative;
    
}
.video_container img.poster {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
}

.video_container .video-js {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 110;
}

.video-js .vjs-big-play-button {
    color: $brand;
    background-color: transparent !important;
    border: none;
    top: 50%;
    left: 50%;
    height: 200px;
    width: 200px;
    line-height: normal;
    font-size: 20rem;
    margin-top: -100px;
    margin-left: -100px;
    transition: color .2s ease-out;
    &:focus, &:hover {
        background-color: transparent !important;
        
    }
    &:hover {
        transition: color .2s ease-out;
        color: #fff;
    }

}
.vjs-poster {
    background-color: #fff;
}
video::-internal-media-controls-download-button {
    display:none;
}

video::-webkit-media-controls-enclosure {
    overflow:hidden;
}

video::-webkit-media-controls-panel {
    width: calc(100% + 30px); /* Adjust as needed */
}

select {
    appearance: none;
    border-radius: 0;
    
}
.select-selected {
    position: relative;
    &:after {
        position: absolute;
        content: "";
        top: 25px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: $brand transparent transparent transparent;
    }
}

ul.navigation li ul.dropdown {
    width: 250px;
}

.modules .item  {
    position: relative;
    .caption {
        box-sizing: border-box;
        width: 300px;
    }
}


::selection {
    background-color: $brand;
    color: white;
}

header {
    .search {
        @media screen and (max-width: 992px) {
            background-color: transparent;
            top: 20px;
        }
    }
}

p, ul li {
    margin-bottom: 1rem;
    font-weight: $font-weight;
    font-size: $font-size;
    line-height: $line-height;
    color: $color-text;
    list-style: none;
}


.hero {
    padding: 50px 0 50px;
}

.form {
    .form-control {
        &.is-invalid-input {
            color: $danger-color;
            border: 1px solid $danger-color !important;
            &:focus {
                background-color: $danger-color !important;
                color: #fff !important;
            }
        }
    }
}
