.overlay {
    width: 100vw;
    height: 100vh;
    @include background-opacity(white, .9);
    // opacity: .6;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;

    visibility: hidden;
    .lds-circle {
        display: inline-block;
        @include center(xy);
      }
    .lds-circle > div {
        display: inline-block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        background: $brand;
        animation: lds-circle 10s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
      
      
}

@keyframes lds-circle {
    0%, 100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
    }
  }