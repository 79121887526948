.students-list {
    
    button.full {
        width: 100%;
        // margin-left: 3%;
        
    }
    a, p {
        height: 52px;
        padding: 15px;
        padding-top: 0;
        display: block;
    }
    
    h3 {
        span {
            font-weight: normal;
            font-size: 20px;
            // color: $brand;
            position: relative;
            .non-payed {
                color: $brand;
                background-color: $light-grey;
                width: 17px;
                height: 17px;
                font-size: 13px;
                font-weight: bold;
                line-height: 13px;
                text-align: center;
                position: absolute;
                border-radius: 50%;
                top: -4px;
                right: -15px;
            }
        }
    }
    .payed, .has-certificate {
        color: $check-color;
    }
    
    .send-mail {
        transition: all .2s ease-out;
        &:hover {
            color: $brand;
        }
    }
    tbody {
        .name-td {
            span {
                display: none;
            }
        }
    }
    table td[class*="col-"], table th[class*="col-"] {
        float: none;
        height: 52px;
        max-height: 52px;
    }
    .dataTables_info {
        display: none;
    }
    input {
        font-weight: 100;
    }
    .remove {
        a:hover {
            color: $danger-color;
            span {
                color: $danger-color; 
            }
        }
        
    }
    label {
        width: 25%;
    }
    input[type="search"] {
        width: 100%;
    }
    td.functions {
        
        a {
            padding: 0;
            display: inline-block;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
                margin-left: 20px;
            }
        }
    }
    th.functions {
        
        a {
            padding: 0;
            display: inline-block;
            margin-right: 20px;
        }
    }
    
}