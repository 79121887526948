.course-module {
    &.hero {
        padding: 100px 0 50px;
        h1 {
            margin-bottom: 0;
        }
        h4 {
            margin: auto;
            width: 50%;
        }
    }
    iframe {
        width: 100%;
        height: 560px;
    }
    .download-btn {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        span {
            margin-right: .5rem;
        }
    }
    aside {
        background-color: transparent;
        &:before {
            display: none;
        }
        h3 {
            margin-top: 0;
        }
        ul.item-list {
            li {
                a {
                    
                    
                    &:hover, &.active {
                        color: $brand;
                        padding-left: 45px;
                        &:before {
                            width: 30px;
                            border-color: $brand;
                            body.dark-mode & {
                                border-color: $brand;
                            }
                        }
                        span { 
                            color: $brand;
                        }

                    }
                    body.dark-mode & {
                        color: $color-text-dark-mode;
                        &:before {
                            border-color: $color-text-dark-mode;
                        }
                        &:hover, &.active {
                            color: $brand;
                        }
                    }
                    &.active {
                        pointer-events: none;
                    }
                    span {
                        font-family: 'Material Icons';
                        display: inline-block;
                        width: auto;
                        padding: 0;
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
    }
}