$font-size: 14px !default;
$font-line: 22px !default;
$font-weight: 300 !default;
$x-rem: false !default;

// rem fallback
@function calculateRem($size, $rem: $x-rem) {
    @if $rem {
        @return ($size / $font-size) * 1rem;
    } @else {
        @return $size;
    }
}


// Font Responsive
@function get-vw($target) {
    $vw-context: (1000 * 0.015) * 1px;
    @return ($target/$vw-context) * 1vw;
}



///*------------------------------------*\
//    #Cross browser multiline ellipsis
//\*------------------------------------*/

@mixin multiLineEllipsis(
    $lineHeight: $font-line,
    $lineCount: 3,
    $bgColor: $white-color
) {
    position: relative;

    overflow: hidden;

    max-height: $lineHeight * $lineCount;
    padding-right: 1rem;

    text-align: justify;

    line-height: $lineHeight;

    &:before {
        position: absolute;
        right: 0;
        bottom: 0;
        content: "...";
    }

    &:after {
        position: absolute;
        right: 0;
        width: 1rem;
        height: 1rem;
        margin-top: 0.2rem;
        content: "";
        background: $bgColor;
    }
}

// color tint and shade

@function shade($color, $percent) {
    @return mix($white-color, $color, $percent);
}

@function tint($color, $percent) {
    @return mix($black-color, $color, $percent);
}

// center vertically and/or horizontally an absolute positioned element

@mixin center($xy: xy) {
    position: absolute;
    @if $xy == xy {
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;

        @include transform(translateX(-50%) translateY(-50%));
    } @else if $xy == x {
        right: auto;
        left: 50%;

        @include transform(translateX(-50%));
    } @else if $xy == y {
        top: 50%;
        bottom: auto;

        @include transform(translateY(-50%));
    } @else if $xy == 0 {
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;

        @include transform(translateX(0) translateY(0));
    }
}

// border radius

@mixin border-radius($radius: 0.25em) {
    border-radius: $radius;
}

// clearfix

@mixin clearfix {
    &:before,
    &:after {
        display: table;

        content: " ";
    }

    &:after {
        clear: both;
    }
}


// antialiasing mode font rendering

@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else if $value == auto {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    } @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

// image with grayscale with hover

@mixin grayscale_element($value) {
    -webkit-filter: #{"grayscale(#{$value})"};
    -moz-filter: #{"grayscale(#{$value})"};
    filter: #{"grayscale(#{$value})"};
}



///*------------------------------------*\
//    #super light grid system
//\*------------------------------------*/

@mixin column($percentage, $float-direction: left) {
    float: $float-direction;

    width: 100% * $percentage;
}

// Force Hardware Acceleration in WebKit

@mixin speed-up {
    @include transform(translateZ(0) rotateY(0deg) rotateZ(360deg));
    @include backface-visibility(hidden);
    -webkit-perspective: 1000;
    will-change: transform;
}

///*------------------------------------*\
//    #ANIMATION
//\*------------------------------------*/

@mixin x-animate($properties, $delay: 0s, $duration: 0.3s, $effect: ease-out) {
    @include transition-property($props);
    @include transition-duration($duration);
    @include transition-timing-function($effect);
    @include transition-delay($delay);
    $props: unquote($properties);
}

@mixin keep-animate(
    $name,
    $count: infinite,
    $duration: 0.6s,
    $effect: ease,
    $delay: 0s
) {
    @include animation-name($name);
    @include animation-duration($duration);
    @include animation-timing-function($effect);
    @include animation-iteration-count($count);
    @include animation-delay($delay);
}

///*------------------------------------*\
//    #GENERATE ALIGN TO LEFT AND RIGHT
//\*------------------------------------*/

@mixin generate-viewports() {
    @for $space from 0 through 100 {
        .vw-#{$space} {
            width: #{$space}vw !important;
        }
        .vh-#{$space} {
            height: #{$space}vh !important;
        }
    }
}

///*------------------------------------*\
//    #GENERATE VIEWPORT
//\*------------------------------------*/

@mixin generate-aligns() {
    @for $space from 0 through 40 {
        .l-#{$space} {
            padding-left: #{$space}% !important;
        }
        .r-#{$space} {
            padding-right: #{$space}% !important;
        }
    }
}

///*------------------------------------*\
//    #GENERATE MARGIN AND PADDING
//\*------------------------------------*/

@mixin generate-xy-space() {
    $step: 5;
    $from: 0;
    $through: ceil(250 / $step);
    $value: "px !important";

    @for $size from $from through $through {
        $size: $size * $step;

        @each $property in "padding", "margin" {
            .#{str-slice($property, 1, 1)}-#{$size} {
                #{$property}: #{$size}#{$value};
            }

            .#{str-slice($property, 1, 1)}y-#{$size} {
                #{$property}-top: #{$size}#{$value};
                #{$property}-bottom: #{$size}#{$value};
            }

            .#{str-slice($property, 1, 1)}x-#{$size} {
                #{$property}-left: #{$size}#{$value};
                #{$property}-right: #{$size}#{$value};
            }

            @each $position in "top", "left", "bottom", "right" {
                .#{str-slice($property, 1, 1)}#{str-slice($position, 1, 1)}-#{$size} {
                    #{$property}-#{$position}: #{$size}#{$value};
                }
            }
        }
    }
}
///*------------------------------------*\
//    #GENERATE FONT sizes
//\*------------------------------------*/


@mixin phone(){
    @media screen and (min-width: $phone-min-width) and (max-height: $phone-max-height){
        @content;
    }
}
// For portrait phone
@mixin portrait-phone() {
    @media screen and (min-width: $phone-min-width) and (max-width: $phone-max-width) and (orientation: portrait) {
        @content;
    }
}
// For landscape phone
@mixin landscape-phone(){
    @media screen and (min-height: $phone-min-height) and (max-height: $phone-max-height) and (orientation: landscape){
        @content;
    }
}
// For portrait tablet and landscape tablet
@mixin tablet(){
    @media screen and (min-width: $tablet-min-width) and (max-height: $tablet-max-height){
        @content;
    }
}
// For portrait tablet
@mixin portrait-tablet() {
    @media screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) and (orientation: portrait) {
        @content;
    }
}
// For portrait ipad
@mixin portrait-ipadpro() {
    @media only screen 
    and (min-device-width: 1024px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}
// For landscape tablet
@mixin landscape-tablet(){
    @media screen and (min-height: $tablet-min-height) and (max-height: $tablet-max-height) and (orientation: landscape){
        @content;
    }
}
// For iPad 1, 2, Mini and Air Landscape
@mixin landscape-small-tablet() {
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: landscape) 
    and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}
// For desktop
@mixin desktop(){
    @media screen and (min-height: $desktop-min-height) and (max-height: $desktop-max-height){
        @content;
    }
}

// Between 
@mixin media-min-max($min, $max) {
    @media (min-width: $min) and (max-width: $max) {
        @content;
    }
}
// BIGGER than 
@mixin bigger-than($res) {
    @media (min-width: $res) {
        @content;
    }
}
@mixin larger-than($res) {
    @media (min-width: $res) {
        @content;
    }
}
// SMALLER than 
@mixin smaller-than($res) {
    @media (max-width: $res) {
        @content;
    }
}

// SMALLER THEAN height
@mixin smaller-height-than($res) {
    @media (max-height: $res) {
        @content;
    }
}

///*------------------------------------*\
//    #BURGER MENU
//\*------------------------------------*/

// (---) top    -> &::before
// [---] middle -> &
// (---) bottom -> &::after

// Vendor prefixes
$sass-burger-add-vendor-prefixes: true !default;

// Burger
@mixin burger(
    $width: 30px,
    $height: 5px,
    $gutter: 3px,
    $color: $black,
    $border-radius: 0,
    $transition-duration: 0.3s,
    $active-color: $black
) {
    position: relative;

    margin-top: $height + $gutter;
    margin-bottom: $height + $gutter;

    user-select: none;
    text-indent: -9999px;

    font-size: 0;

    $burger-height: $height !global;
    $burger-gutter: $gutter !global;

    @if $sass-burger-add-vendor-prefixes {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

    // 1. Fixes jagged edges in Firefox, see issue #10.
    &,
    &::before,
    &::after {
        display: block;

        width: $width;
        height: $height;

        transition-duration: $transition-duration;
        transition-property: background-color, transform;

        outline: 1px solid transparent; // 1
        background-color: $color;
        @if $border-radius != 0 {
            border-radius: $border-radius;
        }

        @if $sass-burger-add-vendor-prefixes {
            -webkit-transition-property: background-color, -webkit-transform;
            -moz-transition-property: background-color, -moz-transform;
            -o-transition-property: background-color, -o-transform;
        }

        @if $sass-burger-add-vendor-prefixes {
            -webkit-transition-duration: $transition-duration;
            -moz-transition-duration: $transition-duration;
            -o-transition-duration: $transition-duration;
        }
    }

    &::before,
    &::after {
        position: absolute;

        content: "";
    }

    &::before {
        top: -($height + $gutter);
    }

    &::after {
        top: $height + $gutter;
    }
}

// Select parts of the burger
@mixin burger-parts {
    &,
    &::before,
    &::after {
        @content;
    }
}

@mixin burger-top {
    &::before {
        @content;
    }
}

@mixin burger-middle {
    & {
        @content;
    }
}

@mixin burger-bottom {
    &::after {
        @content;
    }
}

// Burger animations
@mixin burger-to-cross($color: auto) {
    & {
        background-color: transparent;
    }
    @if ($color != auto) {
        &::before,
        &::after {
            background-color: $color;
        }
    }
    &::before {
        transform: translateY($burger-gutter + $burger-height) rotate(45deg);
        @if $sass-burger-add-vendor-prefixes {
            -webkit-transform: translateY($burger-gutter + $burger-height)
                rotate(45deg);
            -moz-transform: translateY($burger-gutter + $burger-height)
                rotate(45deg);
            -ms-transform: translateY($burger-gutter + $burger-height)
                rotate(45deg);
            -o-transform: translateY($burger-gutter + $burger-height)
                rotate(45deg);
        }
    }
    &::after {
        transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
        @if $sass-burger-add-vendor-prefixes {
            -webkit-transform: translateY(-($burger-gutter + $burger-height))
                rotate(-45deg);
            -moz-transform: translateY(-($burger-gutter + $burger-height))
                rotate(-45deg);
            -ms-transform: translateY(-($burger-gutter + $burger-height))
                rotate(-45deg);
            -o-transform: translateY(-($burger-gutter + $burger-height))
                rotate(-45deg);
        }
    }
}


@mixin alt_parent($parent) {
    @at-root {
        @each $selector in & {
            #{unquote("#{$parent}")}#{$selector} {
                @content;
            }
        }
    }
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
      @return $number / ($number * 0 + 1);
    }
  
    @return $number;
}

@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

@include generate-aligns();;
@include generate-xy-space();
