.course {
    .hero.login-page {
        padding-top: 0;
    }
    ul {
        li {
            position: relative;
            &:before, span:before {
                content: '';
                border-top: 1px solid $brand;
                transition: all .3s;
                width: 20px;
                height: 2px;
                margin-top: -1px;
                left: -25px;
                display: block;
                position: absolute;
                top: 15px;
            }
        }
    }
}