.assets-list {
    li {
        a {
            display: block;
            width: 100%;
            text-overflow: ellipsis;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            padding-right: 1.5rem;
        }
        .course & {
            &::before {
                display: none;
            }
        }
    }
    .download-btn {
        display: flex;
        .material-icons {
            margin-top: -2px;
        }
    }
    
}