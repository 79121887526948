$brand: #eb714e;
$color-text: #555;
$color-secundary: #77cbdc;
$danger-color: #dc3028;
$check-color: #75a646;
$color-text-dark-mode: #eee;
$light-grey: #f5f5f5;


$main-transition: all .3s ease-out;

$font-weight: 300;
$font-size: 1.2em;
$line-height: 1.6;